<template>
  <div class="paragraph relative">
    <ParagraphBaseImageVideoBase
      :overline="overline"
      :headline="headline"
      :link="actualLink"
      :link-action="linkAction"
      :is-editing="isEditing"
      :overlay="overlay"
      :only-show-the-media="onlyShowTheMedia"
    >
      <template #image>
        <MediaVideo
          v-if="video"
          v-bind="typeof video === 'string' ? { video: video } : video"
          ref="mediaVideo"
          :autoplay="autoplayAndLoop && !isEditing"
          :loop="autoplayAndLoop"
          :controls="onlyShowTheMedia"
          :big-play-button="false"
          :poster="poster"
          :muted="muted"
        />
        <div v-if="autoplayAndLoop && isEditing" class="bg-grey-mid">
          Autoplay is disabled while editing
        </div>
      </template>

      <template v-if="actualLink || !hidePlayButton" #callToActionText>
        <span>
          {{
            actualLink
              ? $texts('readMore', 'Read more')
              : $texts('playVideo', 'Play Video')
          }}
        </span>

        <img
          v-if="!actualLink"
          src="@/assets/symbols-from-figma/figma-play.svg"
          class="h-[1em] w-[1em] self-center"
        />
      </template>
    </ParagraphBaseImageVideoBase>
    <div
      class="paragraph-video-volume-icon absolute z-10 cursor-pointer rounded-full"
      :class="{
        muted: muted,
        hidden: playingVideo || noSoundButton,
      }"
    >
      <SpriteSymbol
        class="h-full w-full"
        :name="muted ? 'volume-muted-line-icon' : 'volume-medium-line-icon'"
        @click="muted = !muted"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { MediaVideo } from '#build/components'
import type { ParagraphVideoFragment } from '#build/graphql-operations'
import { getBasicRokkaUrl } from '~/helpers/rokka'

const { isEditing } = defineBlokkli({
  bundle: 'video',
})

export type ParagraphVideoProps = Omit<
  ParagraphVideoFragment,
  'image' | 'video' | 'link'
> & {
  image?: ParagraphVideoFragment['image'] | string
} & { video?: ParagraphVideoFragment['video'] | string } & {
  link?: ParagraphVideoFragment['link'] | string
}

const props = defineProps<ParagraphVideoProps>()

const poster = computed(() => {
  const image = props.image
  return typeof image === 'string'
    ? image
    : getBasicRokkaUrl(image?.image?.file?.rokka?.hash)
})

const actualLink = computed(() => {
  if (props.autoplayAndLoop) {
    if (typeof props.link === 'string') {
      return props.link
    } else {
      return props.link?.uri?.path
    }
  } else {
    return undefined
  }
})

const onlyShowTheMedia = ref(false)
const playingVideo = ref(false)

const mediaVideo = ref<typeof MediaVideo>()

const linkAction = computed(() => () => {
  if (!actualLink.value) {
    if (mediaVideo.value) {
      mediaVideo.value.play()
      muted.value = false
      playingVideo.value = true
      onlyShowTheMedia.value = true
    }
  }
})

const muted = ref(true)
</script>

<style lang="scss" scoped>
.paragraph-video-volume-icon {
  $padding: min(max(1cqw, 10px), 17px);
  padding: calc($padding * 1.35);
  padding-left: $padding;
  bottom: max(3cqw, 24px);
  right: max(3cqw, 24px);
  $size: min(max(5cqw, 48px), 70px);
  width: $size;
  height: $size;
  background: #efefef;
  opacity: 0.6;

  &.muted {
    padding: $padding;
  }
}
</style>
